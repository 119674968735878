angular.module('elogbooksServices').service('azureService', function (
    msalAuthenticationService,
    messenger,
    apiClient,
    $window,
    API_URL,
    $state,
    userManager
) {

    'use strict';

    var self = this;
    var scopes = ["openid"];
    var loginRequestComplete = false;
    self.login = login;
    self.authorise = authorise;
    self.forceUserToLinkAccount = forceUserToLinkAccount;
    self.renewToken = renewToken;
    self.handleAzureLoginRedirect = handleAzureLoginRedirect;

    function renewToken() {
        msalAuthenticationService.acquireTokenSilent(scopes).then(function() {
            // token valid, ignore.
        }, function() {
            userManager.logout();
            $state.go('login');
        });
    }

    function forceUserToLinkAccount(uri) {
        apiClient.get(uri).then(function(ssoResponse) {
            if (ssoResponse && ssoResponse.isSsoUser && !ssoResponse.isUserLinked) {
                $state.go('sso');
            }
        });
    }

    function authorise() {
        msalAuthenticationService.loginPopup(scopes).then(function (response) {
            apiClient.create('/sso/authorise', {
                accessToken: response,
                uid: msalAuthenticationService.getUser().userIdentifier
            }).then(function (response) {
                if (response) {
                    $state.go('dashboard.user.dashboard', {reload: false}).then(function () {
                        messenger.success('LINK_TO_AZURE_SUCCESSFUL');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    function login() {
        msalAuthenticationService.loginRedirect(scopes);
    }

    function handleAzureLoginRedirect(event) {
        return new Promise(function(resolve, reject) {
            const token = $window.localStorage.getItem('azureToken');

            if (token) {
                const userResource = $window.localStorage.getItem('userResource');

                if (!loginRequestComplete && !userResource) {
                    event.preventDefault();

                    const authuser = msalAuthenticationService.getUser();

                    if (!authuser) {
                        $window.localStorage.removeItem('azureToken');
                        $window.sessionStorage.clear();

                        resolve({message: 'REQUEST_ERROR'});
                    } else {
                        const sso = ssoLogin(token, API_URL, authuser.userIdentifier).then(function (response) {
                            loginRequestComplete = true;

                            if (response) {
                                $window.localStorage.setItem('token', response.token);
                                $window.localStorage.setItem('tokenExpiration', response.expiration*1000);
                                $window.localStorage.removeItem('recaptchaRequired');
                                $window.localStorage.removeItem('azureToken');

                                userManager.getUserResourceAndTokenLink().then(function (response) {
                                    if (response) {
                                        $state.go('dashboard.user.dashboard', {}, {reload: true});
                                    } else {
                                        $window.localStorage.removeItem('token');
                                        $window.localStorage.removeItem('tokenExpiration');
                                    }
                                });
                            }
                        }).catch(function (error) {
                            $window.localStorage.removeItem('azureToken');
                            $window.sessionStorage.clear();

                            return {message: 'USER_NOT_LINKED_MICROSOFT'};
                        });

                        sso.then(function(response) {
                            resolve(response);
                        });
                    }
                }
            }
        });
    }

    function ssoLogin(token, url, userIdentifier) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                url: url + '/sso/login',
                method: 'POST',
                data: JSON.stringify({
                    uid: userIdentifier,
                    accessToken: token,
                    source: 'sso'
                }),
                contentType: 'application/json',
                success: function(response) {
                    resolve(response);
                },
                error: function(xhr, status, error) {
                    reject(error);
                }
            });
        });
    }
});
