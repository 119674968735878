angular.module('elogbooksServices').service('siteAdvancedFiltersModal', ['$uibModal', 'advancedFilterService', function ($uibModal, advancedFilterService) {
    return {
        open: function (collections, resources, config) {
            return $uibModal.open({
                templateUrl: '/modules/common/modals/advancedFilters/modal-advanced-filters-modal.html',
                size: 'xl',
                controller: 'advancedSiteFiltersModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        var defaultConfig = {
                            view: 'site',
                            titleMessage: 'ARE_YOU_SURE_HEADER',
                            bodyMessage: 'ARE_YOU_SURE'
                        };

                        return angular.extend({}, defaultConfig, config);
                    },
                    user: resources.user,
                    siteGroupCollectionResponse: collections[0],
                    regionCollectionResponse: collections[1],
                    siteAssociateCollectionResponse: collections[2],
                    associateTypeCollectionResponse: collections[3],
                    approversCollectionResponse: advancedFilterService.getFilterData('approvers', resources, { isApprover: true, status: "active" }),
                    sectorCollectionResponse: advancedFilterService.getFilterData('sectors', resources)
                }
            });
        }
    };
}]);
