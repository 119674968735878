(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            LINKED: 'Linked',
            LINKED_TO_OKTA: 'Linked to OKTA',
            LINK_TO_OKTA: 'Link to OKTA',
            LINK_TO_AZURE: 'Link to Microsoft',
            LINK_TO_OKTA_SUCCESSFUL: 'Account Linked to OKTA Successfully',
            LINK_TO_AZURE_SUCCESSFUL: 'Account Linked to Microsoft Successfully',
            LINK_ACCOUNT_TEXT_OKTA: 'You must login to your OKTA account.',
            LINK_ACCOUNT_TEXT_AZURE: 'You must login to your Microsoft account.',
            SSO_USERS: 'SSO Users',
            SSO_USERS_LIST: 'SSO Users List',
            LOGIN_WITH_OKTA: 'Log in using OKTA',
            LOGIN_WITH_AZURE: 'Log in using Microsoft',
            AZURE: 'Microsoft',
            OKTA: 'Okta',
            INVALID_ACCESS_TOKEN: 'Invalid SSO Access token. Please try again.',
            DOMAIN: 'Domain',
            USER_NOT_LINKED_MICROSOFT: 'Account is not linked yet. Please log in through normal login process to link it with your Microsoft account.'
        });
    }
})();
