angular.module('elogbooksDirectives').directive('searchCriteria', function (
    $rootScope,
    lodash,
    jobAdvancedFiltersModal,
    quoteAdvancedFiltersModal,
    siteAdvancedFiltersModal,
    taskAdvancedFiltersModal,
    serviceProviderAdvancedFiltersModal,
    auditsAdvancedFiltersModal,
    fileAdvancedFiltersModal,
    filterCriteriaService,
    $state,
    moment,
    $stateParams
) {
    return {
        restrict: 'AE',
        scope: {
            'criteria': '=',
            'collections': '=',
            'stateParams': '=',
            'callback': '=criteriaCallback',
            'filtersTemplate': '@',
            'advancedFiltersTemplate': '@',
            'advancedFiltersController': '@',
            'resolveResources': '=',
            'tidyTablesModel' : '=',
            'canSaveFilters': '@',
            'config' : '=',
            'ignoredFilters': '=',
            'pageName': '@',
            'loading': '=?'
        },
        templateUrl: function(elem,attrs) {
            var url;
            if (attrs.templateUrl) {
                url = '/modules/directives/search-criteria/' + attrs.templateUrl + '.html';
            } else {
                url = '/modules/directives/search-criteria/search-criteria.html';
            }
            return url;
        },
        link: function(scope, element, attrs) {
            scope.loading = !!scope.loading;
            if (scope.advancedFiltersController) {
                scope.advanceModalOpen = $rootScope.advanceModalOpen = false;
            }

            $rootScope.$watch('advanceModalOpen', function(newValue) {
                scope.advanceModalOpen = newValue;
            });

            scope.save = function() {
                var params = null;
                angular.forEach(scope.criteria, function(criterion) {
                    criterion.opened = false;
                    if (criterion.type === 'date-range') {
                        if ((lodash.has(criterion, 'value.startDate') && lodash.has(criterion, 'value.endDate'))
                            && (!lodash.isEmpty(criterion.value.startDate) && !lodash.isEmpty(criterion.value.startDate))
                        ) {
                            criterion.value = criterion.value.startDate.toISOString(true) + ',' + criterion.value.endDate.endOf('day').toISOString(true);
                        } else {
                            criterion.value = null;
                        }
                    }

                    if (criterion.type === 'date'
                        && lodash.has(criterion, 'value')
                        && criterion.value !== null
                    ) {
                        var selectedDate = new Date(criterion.value);
                        if (criterion.end) {
                            criterion.value = moment(moment(selectedDate).format('MM/DD/YYYY 23:59:59'));
                        } else {
                            criterion.value = moment(moment(selectedDate).format('MM/DD/YYYY 00:00:00'));
                        }
                    }

                    if (criterion.type === 'month-year') {
                        if (criterion.value === null || criterion.value === undefined) {
                            criterion.value = null;
                        } else {
                            criterion.value = moment(new Date(criterion.value)).format('MM/DD/YYYY');
                        }
                    }

                    if (criterion.type === 'selectwidget' || criterion.type === 'data_select' ) {
                        if (!criterion.value && criterion.options.selected) {
                            criterion.value = criterion.options.selected.value;
                        }
                    }

                    if (criterion.type === 'jsonselectwidget') {
                        if (!criterion.value && criterion.options.selected) {
                            criterion.value = JSON.stringify(criterion.options.selected.value);
                        }

                        if (criterion.value && typeof criterion.value === 'object') {
                            criterion.value = JSON.stringify(criterion.value);
                        }
                    }

                    params = lodash.zipObject(lodash.keys(scope.criteria), lodash.map(scope.criteria, 'value'));

                    if (criterion.type === 'autocompletemulti' || criterion.type === 'autocomplete') {
                        criterion.value = params[criterion.param] = criterion.options.selectedItems.map(function (item) {
                            return item.href;
                        });
                    }
                });

                if (scope.callback) {
                    scope.callback(params);
                } else {
                    scope.$parent.vm.saveParams(params);
                }
            };
            scope.update = function(clearFilters, cleanSaveFilters) {
                if (typeof clearFilters === 'undefined') {
                    clearFilters = false;
                }

                angular.forEach(scope.criteria, function(criterion) {
                    if (criterion.type === 'date-range') {
                        if ((lodash.has(criterion, 'value.startDate') && lodash.has(criterion, 'value.endDate'))
                            && (!lodash.isEmpty(criterion.value.startDate) && !lodash.isEmpty(criterion.value.startDate))
                        ) {
                            criterion.value = criterion.value.startDate.toISOString(true) + ',' + criterion.value.endDate.endOf('day').toISOString(true);
                        } else {
                            criterion.value = null;
                        }
                    }

                    if (criterion.type === 'date'
                        && lodash.has(criterion, 'value')
                        && criterion.value !== null
                    ) {
                        var selectedDate = new Date(criterion.value);

                        if (criterion.end) {
                            criterion.value = moment(moment(selectedDate).format('MM/DD/YYYY 23:59:59'));
                        } else {
                            criterion.value = moment(moment(selectedDate).format('MM/DD/YYYY 00:00:00'));
                        }
                    }

                    if (criterion.type === 'month-year') {
                        if (criterion.value === null || criterion.value === undefined) {
                            criterion.value = null;
                        } else {
                            criterion.value = moment(new Date(criterion.value)).format('MM/DD/YYYY');
                        }
                    }

                    if (criterion.type === 'selectwidget' || criterion.type === 'data_select' ) {
                        if (!criterion.value && criterion.options.selected) {
                            criterion.value = criterion.options.selected.value;
                        }

                        if (criterion.value && typeof criterion.value === 'string' && criterion.value.indexOf('/ ') > -1) {
                            var idsArray = criterion.value.split('/ ');
                            criterion.value = idsArray[idsArray.length-1];
                        }
                    }

                    if (criterion.type === 'autocompletemulti' || criterion.type === 'autocomplete') {
                        if (!criterion.value && criterion.options.selectedItems) {
                            criterion.value = criterion.options.selectedItems.map(function (item) {
                                return item.href;
                            })
                        }
                    }

                    if (criterion.type === 'jsonselectwidget') {
                        if (!criterion.value && criterion.options.selected) {
                            criterion.jsonValue = criterion.options.selected.value;
                            criterion.value = JSON.stringify(criterion.options.selected.value);
                        }

                        if (criterion.value && typeof criterion.value === 'object') {
                            criterion.jsonValue = criterion.value;
                            criterion.value = JSON.stringify(criterion.value);
                        }
                    }

                    if (criterion.type === 'jobsDueBy') {
                        if (criterion.value && typeof criterion.value === 'object') {
                            criterion.value = JSON.stringify(criterion.value);
                        }
                    }

                    if (criterion.type === 'assetScore') {
                        if (criterion.value && typeof criterion.value === 'object') {
                            criterion.value = JSON.stringify(criterion.value);
                        }
                    }

                    if (criterion.type === 'jsonselectwidget' && criterion.title === 'SURVEY') {
                        try {
                            if (criterion.value && $stateParams.survey) {
                                var criterionSurveyId = JSON.parse(criterion.value).v;
                                var surveyId = JSON.parse($stateParams.survey).v;
                                if (surveyId !== criterionSurveyId) {
                                    $stateParams.version = null;
                                    scope.criteria.version.value = null;
                                }
                            }
                        } catch (error) {
                            console.log('Could not parse $stateParams.survey or criterion.survey', error)
                        }
                    }

                    if (criterion.type === 'date-time-range') {
                        if (criterion.hasOwnProperty('optionParam')) {
                            $state.params[criterion.optionParam] = criterion.currentOption === undefined ? criterion.defaultOption : criterion.currentOption;
                        }
                    }

                    // Priority filter has like 2 filters in one, only one of them can be sent to the request
                    if (criterion.type === 'options' && (criterion.title === 'SELECT_PRIORITY' || criterion.title === 'JOB_PRIORITY') && !clearFilters) {
                        if (criterion.valueFilterByName && criterion.valueFilterByName !== '') {
                            scope.criteria.jobSpecificSpPriority = {
                                value: criterion.valueFilterByName,
                                opened: false,
                                show: false
                            };

                            scope.criteria.jobPriority = {
                                value: null,
                                opened: true,
                                type: 'options',
                                title: 'SELECT_PRIORITY'
                            };
                            $stateParams.jobPriority = null;
                        } else if (criterion.value && scope.criteria.jobPriority) {
                            scope.criteria.jobSpecificSpPriority = {
                                value: null,
                                opened: false,
                                show: false
                            };
                            $stateParams.jobSpecificSpPriority = null;
                        } else {
                            $stateParams.jobSpecificSpPriority = null;
                            scope.criteria.jobSpecificSpPriority = {
                                value: null,
                                opened: false,
                                show: false
                            };
                        }
                    } else if (clearFilters) {
                        delete $stateParams.jobSpecificSpPriority;
                        criterion.valueToShow = null;
                        criterion.valueFilterByName = null;
                    }

                    if (criterion.type === 'options' && criterion.value && criterion.clearValue) {
                        criterion.isClear = lodash.isEqual(criterion.value, criterion.clearValue);
                    }
                });

                var params = lodash.zipObject(lodash.keys(scope.criteria), lodash.map(scope.criteria, 'value'));
                // Retain additional params i.e. advanced filters
                $stateParams = angular.extend({}, $state.params, params);

                var override = {};
                // Reset to first page
                override[scope.pageName || 'page'] = '1';

                params = angular.extend({}, $stateParams, override);

                if (scope.callback) {
                    if (!clearFilters) {
                        scope.callback(params);
                    }
                } else {
                    scope.$parent.vm.saveParams(params);
                }

                if (filterCriteriaService.checkFilterChanges(params) || cleanSaveFilters) {
                    filterCriteriaService.setSelectedFilter(null);
                }
            };

            scope.clearValues = function() {
                $rootScope.$emit('clearValues');
            };

            scope.customCallback = function(callbackFunction, value) {
                scope.$parent.vm[callbackFunction](value);
            };

            scope.clear = function() {
                if (scope.stateParams) {
                    $stateParams = scope.stateParams;
                }
                var params = $stateParams;

                // Sometimes we want clear to reset back to a certain value, rather than null.
                var clearValues = [];
                lodash.each(this.criteria, function(item, key) {
                    if (item.clearValue) {
                        clearValues[key] = item.clearValue;
                    }
                    // This was erasing resource parameters, breaking clear-filters on nested list views. This prevents resource parameters being removed on clear.
                    if (key.toLowerCase().indexOf('resource') === -1 && key.toLowerCase().indexOf('audittype') === -1) {
                        item.value = item.clearValue != null ? item.clearValue : null;
                        if (item.options) {
                            item.options.selected = null;
                            item.options.selectedItems = [];
                        }
                    }
                });

                // Clear additional state params beyond criteria i.e. advanced filters
                lodash.each($stateParams, function(item, key) {
                    // This was erasing resource parameters, breaking clear-filters on nested list views. This prevents resource parameters being removed on clear.
                    if (key.toLowerCase().indexOf('resource') === -1 && key.toLowerCase().indexOf('audittype') === -1) {
                        params[key] = clearValues[key] != null ? clearValues[key] : null;
                        $state.params[key] = params[key];
                    }

                    // Remove hidden filter from dashboard clickthrough
                    if (key === 'jobsHasDocumentType') {
                        params[key] = null;
                    }
                });

                this.update(false, true);
            };

            scope.clearSelected = function(criterion) {
                criterion.valueFilterByName = null;
                criterion.valueToShow = null;
                $stateParams.jobSpecificSpPriority = null;

                this.update(false, true);
            }

            scope.advancedFilters = function(savedCriteriaApplied) {
                $rootScope.advanceModalOpen = true;

                switch (scope.advancedFiltersTemplate) {
                    case 'audits':
                        auditsAdvancedFiltersModal.open(scope.collections, scope.resolveResources).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                    case 'serviceproviders':
                        serviceProviderAdvancedFiltersModal.open(scope.collections, scope.resolveResources, scope.config).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                    case 'job':
                        jobAdvancedFiltersModal.open(scope.collections, scope.resolveResources, {savedCriteriaApplied: savedCriteriaApplied}).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                    case 'quote':
                        quoteAdvancedFiltersModal.open(scope.collections, scope.resolveResources).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                    case 'site':
                        siteAdvancedFiltersModal.open(scope.collections, scope.resolveResources).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                    case 'task':
                        taskAdvancedFiltersModal.open(scope.collections, scope.resolveResources, scope.config).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                    case 'file':
                        fileAdvancedFiltersModal.open(scope.collections, scope.resolveResources, scope.config).result.then(function (resolvedResponse) {
                            $rootScope.advanceModalOpen = false;
                        }, function (rejectionResponse) {
                            $rootScope.advanceModalOpen = false;
                        });
                        break;
                }
            };

            // Apply highlight on selected filter
            if (filterCriteriaService.getSelectedFilter()) {
                var count = 0;
                var selectedQuery = JSON.parse(filterCriteriaService.getSelectedFilter().query);
                for (var key in selectedQuery) {
                    if (selectedQuery.hasOwnProperty(key)
                        && selectedQuery[key]
                        && !lodash.includes(scope.ignoredFilters, key)
                        && (!lodash.isArray(selectedQuery[key]) || (lodash.isArray(selectedQuery[key]) && selectedQuery[key].length) || (key === 'jobStatus'))
                    ) {
                        count++;

                        // If the state params don't match the selected query we need to remove
                        if (scope.stateParams
                            && !lodash.isEqual(scope.stateParams[key], selectedQuery[key])
                            && !(lodash.isArray(selectedQuery[key]) && selectedQuery[key].length === 0)
                        ) {
                            count = 0;
                            filterCriteriaService.setSelectedFilter(null);
                            break;
                        }

                        // jobSpecificPriority is part of jobPriority, it needs to be rename to highlight jobPriority filter
                        if (key === 'jobSpecificSpPriority') {
                            key = 'jobPriority';
                        }

                        if (scope.criteria.hasOwnProperty(key)) {
                            scope.criteria[key].highlighted = true;
                        }

                    }
                }

                if (count) {
                    scope.criteriaHighlighted = true;
                    scope.savedCriteriaAppiled = count;
                }
            }
        }
    };
});

angular.module('elogbooksDirectives').directive('searchCriterion', function($stateParams, $rootScope, lodash, $state, $translate) {
    return {
        scope: true,
        controller: function ($scope, $element, $timeout, filterCriteriaService) {
            this.initiated = false;

            // can be overwritten by child components
            this.canUpdate = true;

            if ($scope.$parent.criterion.type === 'date' && $scope.$parent.criterion.value !== null) {
                this.value = $scope.$parent.criterion.value = new Date($scope.$parent.criterion.value);
            } else {
                this.value = angular.copy($scope.$parent.criterion.value);
                this.valueFilterByName = angular.copy($scope.$parent.criterion.valueFilterByName);
            }

            this.options = $scope.$parent.criterion.options;
            this.update = function() {
                if (!this.canUpdate) {
                    return;
                }

                if ($scope.$parent.criterion.callback) {
                    $scope.$parent.customCallback($scope.$parent.criterion.callback, this.value);
                }

                if ($scope.$parent.criterion.type === 'selectwidget' || $scope.$parent.criterion.type === 'data_select') {
                    $scope.$parent.criterion.value =  lodash.has(this.options, 'selected.value') ? this.options.selected.value : null;
                } else if ($scope.$parent.criterion.type === 'autocompletemulti' || $scope.$parent.criterion.type === 'autocomplete') {
                    $scope.$parent.criterion.value = $scope.$parent.criterion.options.selectedItems.map(
                        function (item) {
                            return item.href;
                        }
                    );
                } else if ($scope.$parent.criterion.type === 'jsonselectwidget') {
                    this.options = $scope.$parent.criterion.options;
                    $scope.$parent.criterion.value = lodash.has(this.options, 'selected.value') ? JSON.stringify(this.options.selected.value) : null;
                } else {
                    $scope.$parent.criterion.value = this.value;
                }

                if ($scope.$parent.criteria.jobsDueBy !== undefined) {
                    // Disable or enable jobsDueBy widget
                    if ($scope.$parent.criterion.enableDisableJobsDueBy &&
                        this.value !== null) {
                        $scope.$parent.criteria.jobsDueBy.options.isDisabled = true;
                        $scope.$parent.criteria.jobsDueBy.options.clear();
                    } else {
                        var isDisabled = false;
                        lodash.each($scope.$parent.criteria, function (item) {
                            if (item.enableDisableJobsDueBy && item.value !== null) {
                                isDisabled = true;
                                $scope.$parent.criteria.jobsDueBy.options.clear();
                                return false;
                            }
                        });
                        $scope.$parent.criteria.jobsDueBy.options.isDisabled = isDisabled;
                    }
                }

                // Priority filter has like 2 filters in one, only one of them can be sent to the request
                if ($scope.$parent.criterion.type === 'options' && $scope.$parent.criterion.title === 'JOB_PRIORITY') {

                    //We use a temporal variable to keep the value of advanced input text filter, so we don't show the x button till press save
                    if ($scope.$parent.criterion.tempValueFilterByName && $scope.$parent.criterion.tempValueFilterByName !== '') {
                        $scope.$parent.criterion.valueFilterByName = $scope.$parent.criterion.tempValueFilterByName;
                        $scope.$parent.criterion.valueToShow = $scope.$parent.criterion.tempValueFilterByName;
                    }

                    if ($scope.$parent.criterion.valueFilterByName) {
                        $scope.$parent.criteria.jobSpecificSpPriority = {
                            title: $scope.$parent.criterion.valueFilterByName,
                            value: $scope.$parent.criterion.valueFilterByName,
                            name: $scope.$parent.criterion.valueFilterByName,
                            show: false
                        };

                        $scope.$parent.criterion.valueToShow = $scope.$parent.criterion.valueFilterByName;
                        $scope.$parent.criterion.value = null;
                        $stateParams.jobPriority = null;
                    } else if ($scope.$parent.criterion.value && $scope.$parent.criteria.jobPriority) {
                        delete $scope.$parent.criteria.jobSpecificSpPriority;
                        $stateParams.jobSpecificSpPriority = null;
                    } else if (
                        $scope.$parent.criterion.value &&
                        $scope.$parent.criteria.jobPriority ||
                        ($scope.$parent.criterion.valueFilterByName === '')
                        ) {
                        delete $scope.$parent.criteria.jobSpecificSpPriority;
                        $stateParams.jobSpecificSpPriority = null;
                        $scope.$parent.criterion.valueToShow = null;
                    }
                }

                if ($scope.$parent.criterion.type === 'options' && $scope.$parent.criterion.clearValue) {
                    $scope.$parent.criterion.defaultValue = lodash.isEqual($scope.$parent.criterion.value, $scope.$parent.criterion.clearValue);
                }

                if ($scope.$parent.criterion.hasOwnProperty('updateValue')) {
                    $scope.$parent.criterion.updateValue();
                }

                $scope.$parent.save();
            };
            this.save = function() {
                if (!this.canUpdate) {
                    return;
                }

                if ($scope.$parent.criterion.type === 'selectwidget' || $scope.$parent.criterion.type === 'data_select') {
                    $scope.$parent.criterion.value =  lodash.has(this.options, 'selected.value') ? this.options.selected.value : null;
                } else if ($scope.$parent.criterion.type === 'jsonselectwidget' && typeof this.options.selected !== 'object') {
                    $scope.$parent.criterion.value = lodash.has(this.options, 'selected.value') ? JSON.stringify(this.options.selected.value) : null;
                } else if ($scope.$parent.criterion.type === 'jobsDueBy') {
                    if (this.options.type) {
                        $scope.$parent.criterion.value = JSON.stringify({
                            t: this.options.type,
                            f: this.options.frequency,
                            i: this.options.interval,
                        });
                    } else {
                        $scope.$parent.criterion.value = null;
                    }
                } else if ($scope.$parent.criterion.type === 'assetScore') {
                    if (this.options.selected) {
                        var soIds = [];
                        var so = null;

                        if(this.options.scoreOptions.selectedItems.length) {
                            //scoreOption
                            so = {
                                o: this.options.scoreOptions.selectedItems.map(
                                    function (item, key) {
                                        soIds[key] =  item.href;
                                        return { value: item.value, href: item.href };
                                    }
                                ),
                                i: soIds
                            };
                        }
                        $scope.$parent.criterion.value = JSON.stringify({
                            //scoreType
                            st: {
                                i: this.options.selected.object.id,
                                v: this.options.selected.value
                            },
                            so: so
                        });
                    } else {
                        $scope.$parent.criterion.value = null;
                    }
                } else if ($scope.$parent.criterion.type === 'autocompletemulti' || $scope.$parent.criterion.type === 'autocomplete') {
                    if ($scope.$parent.criterion.options.selectedItems) {
                        $scope.$parent.criterion.value = $scope.$parent.criterion.options.selectedItems.map(
                            function (item) {
                                return item.href;
                            }
                        );
                    } else {
                        $scope.$parent.criterion.value = [];
                    }
                } else if (this.valueFilterByName && this.valueFilterByName !== '') {
                    $scope.$parent.criterion.valueFilterByName = this.valueFilterByName;
                } else {
                    $scope.$parent.criterion.value = this.value;
                }

                if ($scope.$parent.criterion.hasOwnProperty('saveValue')) {
                    $scope.$parent.criterion.saveValue();
                }

                $scope.$parent.update();
            };

            $rootScope.$on('clearValues', function () {
                if ($scope.searchCriterionCtrl.options && $scope.searchCriterionCtrl.options.disabled) {
                    return;
                }

                $rootScope.skipDirtyCheck = true;
                $rootScope.forceDirtyConfirm = false;

                $scope.searchCriterionCtrl.clearValue();

                if ($scope.searchCriterionCtrl.options && typeof $scope.searchCriterionCtrl.options.clear === 'function') {
                    $scope.searchCriterionCtrl.options.clear();
                }

                var params = $stateParams;

                lodash.each($stateParams, function(item, key) {
                    // This was erasing resource parameters, breaking clear-filters on nested list views. This prevents resource parameters being removed on clear.
                    if (key.toLowerCase().indexOf('resource') === -1) {
                        params[key] = null;
                    }
                });

                $scope.$parent.update(true);
            });

            this.clearValue = function(paramKey) {
                if ($scope.$parent.criterion.hasOwnProperty('onValueClear')) {
                    $scope.$parent.criterion.onValueClear();
                }

                if ($scope.$parent.criterion.type === 'date-range') {
                    $scope.searchCriterionCtrl.value = {
                        startDate: null,
                        endDate: null
                    };
                } else if ($scope.$parent.criterion.type === 'jsonselectwidget' ||
                        $scope.$parent.criterion.type === 'selectwidget' ||
                        $scope.$parent.criterion.type === 'data_select' ||
                        $scope.$parent.criterion.type === 'autocompletemulti' ||
                        $scope.$parent.criterion.type === 'autocomplete'
                ) {
                    $scope.$parent.criterion.options.selected = null;
                    $scope.$parent.criterion.options.selectedItems = [];
                    $scope.searchCriterionCtrl.value = null;
                    $scope.searchCriterionCtrl.update(true);
                } else {
                    $scope.searchCriterionCtrl.value = $scope.$parent.criterion.clearValue || null;
                }

                if ($scope.$parent.criterion.options) {
                    $scope.$parent.criterion.options.selected = null;

                    if ($scope.$parent.criterion.clearValue) {
                        $scope.$parent.criterion.value = $scope.$parent.criterion.clearValue;
                        $scope.$parent.update();
                    } else {
                        $scope.$parent.criterion.value = null;
                    }

                    if ($scope.$parent.criterion.title === 'SELECT_PRIORITY') {
                        $scope.$parent.criterion.valueFilterByName = null;
                        $scope.searchCriterionCtrl.valueFilterByName = null;
                        $scope.$parent.criterion.multiple = true;
                        delete $stateParams.jobSpecificSpPriority;
                    }
                }

                if ($scope.$parent.criterion.type === 'jobsDueBy') {
                    $scope.$parent.criterion.options.clear();
                    // Re-enable the filter
                    $scope.$parent.criterion.options.isDisabled = false;
                }

                if ($scope.$parent.criterion.type === 'assetScore') {
                    $scope.$parent.criterion.options.clearValue();
                }

                if ($scope.$parent.criterion.type === 'asset') {
                    $scope.$parent.criterion.options.assets.clear(0);
                }

                if ($scope.$parent.criterion.type === 'date-range') {
                    $scope.$parent.criterion.value = {
                        startDate: null,
                        endDate: null
                    };
                } else {
                    $scope.$parent.criterion.value = null;
                }

                if ($scope.$parent.criterion.param) {
                    $stateParams[$scope.$parent.criterion.param] = null;
                } else {
                    $stateParams[paramKey] = null;
                }

                const criterion = $scope.$parent.criterion;
                if (criterion.type === 'date-time-range') {
                    if (criterion.hasOwnProperty('defaultOption')) {
                        criterion.currentOption = criterion.defaultOption;
                    }
                    criterion.value = criterion.clearValue;
                    criterion.opened = false;
                    $scope.$parent.update(false);
                } else {
                    criterion.opened = false;
                    $scope.$parent.update(true);
                }

                $state.params[paramKey] = null;
            };

            if ($scope.$parent.criterion.type === 'date-range') {
                this.options = angular.extend({}, this.options, {
                    autoApply: true
                });
            }

            if ($scope.$parent.criterion.type === 'date') {
                this.options = angular.extend({}, this.options, {
                    autoApply: true
                });
            }

            if ($scope.$parent.criterion.type === 'month-year') {
                this.options = angular.extend({}, this.options, {
                    autoApply: true
                });
            }

            //Auto submit
            if ($scope.$parent.criterion.type === 'jsonselectwidget') {
                this.options.onSelect = function () {
                    if (!this.itemValuePath) {
                        this.itemValuePath = 'id';
                    }

                    if (!this.itemTitlePath) {
                        this.itemTitlePath = 'name';
                    }

                    const jsonValue = $scope.$parent.criterion.jsonValue = {
                        v:lodash.get(this.selected.object, this.criteriaValuePath ? this.criteriaValuePath : this.itemValuePath),
                        n:lodash.get(this.selected.object, this.itemTitlePath),
                        s:this.selected.suffix ? this.selected.suffix : null 
                    };

                    $scope.$parent.criterion.value = JSON.stringify(jsonValue);

                    $scope.$parent.update();
                };
                this.options.onRemove = this.clearValue;
            }

            //Auto submit
            if ($scope.$parent.criterion.type === 'selectwidget' || $scope.$parent.criterion.type === 'data_select') {
                this.options.onSelect = function () {
                    $scope.$parent.criterion.value = lodash.get(this.selected.object, this.itemValuePath);
                    $scope.$parent.update();
                };
                this.options.onRemove = this.clearValue;
            }

            // if jobSpecificSpPriority input text is empty we need to keep the value
            this.close = function () {
                if ($scope.$parent.criterion.valueFilterByName || $scope.$parent.criterion.valueFilterByName === '') {
                    $scope.$parent.criterion.valueToShow = $stateParams.jobSpecificSpPriority;
                }
            }

            this.clearSelected = function (criterion) {
                criterion.tempValueFilterByName = null;
                criterion.valueFilterByName = null;
                criterion.valueToShow = null;
                delete $stateParams.jobSpecificSpPriority;
                delete $scope.$parent.criteria.jobSpecificSpPriority;
                criterion.value = null;
                this.value = null;
                this.valueFilterByName = null;
                this.update();
            }

            this.checkQuickFilterState = function(criterion) {
                if (!criterion.quickFilter) {
                    return null;
                }
                var quickFilterState;
                lodash.each(criterion.quickFilter.filters, function(filter) {
                    if (lodash.isEqual(normalise(filter.values), normalise(criterion.value))) {
                        quickFilterState = filter.title;
                    };
                });

                return quickFilterState;
            }

            $scope.quickFilterState = this.checkQuickFilterState($scope.$parent.criterion);

            function normalise(value) {
                if (value === undefined) {
                    return null;
                }
                if (Array.isArray(value) && value.length === 0) {
                    return null;
                }

                return value;
            }

            if ($scope.$parent.criterion.type === 'options') {
                let options = $scope.$parent.criterion.options;

                if (
                    $scope.$parent.criterion.value &&
                    $scope.$parent.criterion.type === 'options' &&
                    $scope.$parent.criterion.title === 'SELECT_PRIORITY' &&
                    $scope.$parent.criterion.value.length === 1
                ) {
                    var result = $scope.$parent.criterion.options.filter(function(item) {
                        return item.value === $scope.$parent.criterion.value[0];
                    })

                    if (result.length === 0) {
                        $scope.$parent.criterion.options.push({
                            title: $translate.instant('INACTIVE_PRIORITY'),
                            value: $scope.$parent.criterion.value[0],
                            isChild: false
                        });
                    }
                }

                if ($scope.$parent.criterion.clearValue) {
                    $scope.$parent.criterion.defaultValue = lodash.isEqual($scope.$parent.criterion.value, $scope.$parent.criterion.clearValue);
                }

                $timeout(function() {
                    $element.find('option').each(function(index, option) {
                        if (options !== undefined) {
                            let optionData = options.hasOwnProperty(index) ? options[index] : null;

                            if (optionData && optionData.hasOwnProperty('optionStyle')) {
                                jQuery(option).css(optionData.optionStyle);
                            }

                            if (optionData && optionData.hasOwnProperty('displayedValueStyle')) {
                                $element.find('.option-value-' + optionData.value).css(optionData.displayedValueStyle);
                            }
                        }
                    });
                });
            }
        },
        controllerAs: 'searchCriterionCtrl'
    };
});
