(function(){
    'use strict';

    angular
        .module('elogbooks.user')
        .controller('UserLayoutController', UserLayoutController);
    UserLayoutController.$inject = [
        'user',
        '$state',
        'siteCollectionResponse',
        'selectedSiteResponse',
        '$rootScope',
        '$window',
        'rootResourceResponse',
        'lodash',
        '$stateParams',
        'userCollectionResponse',
        'apiClient',
        'userManager',
        'dashboardService',
        'prioritiesResponse',
        'modulesService'
    ];

    function UserLayoutController(
        user,
        $state,
        siteCollectionResponse,
        selectedSiteResponse,
        $rootScope,
        $window,
        rootResourceResponse,
        lodash,
        $stateParams,
        userCollectionResponse,
        apiClient,
        userManager,
        dashboardService,
        prioritiesResponse,
        modulesService
    ) {
        var vm = this;
        vm.user = user;
        vm.dashboard = dashboardService;
        vm.selectedSite = selectedSiteResponse ? selectedSiteResponse.getLink('self') : null;
        vm.showFilters = false;
        vm.state = $state;
        vm.modulesService = modulesService;

        vm.titleClasses = $state.current.name.indexOf('dashboard.user.dashboard') === -1 ? 'col-md-2 col-sm-3' : 'col-2 col-md-3 col-sm-6';
        vm.filterClasses = $state.current.name.indexOf('dashboard.user.dashboard') === -1 ? 'col-md-10 col-sm-9 filters' : 'col-10 col-md-9 col-sm-6 filters';

        vm.showFilters = userManager.hasPermission('site_permission_view_filter_bar');

        vm.checkWidgets = checkWidgets;

        vm.prioritiesResponse = prioritiesResponse;

        vm.siteSelect = {
            rootName: 'site',
            responseKeyPath: 'sites',
            response: siteCollectionResponse,
            link: siteCollectionResponse.getLink('self'),
            onSelect: changeSelectedSite,
            onRemove: removeSelectedSite,
            selected: selectedSiteResponse ? {
                title: selectedSiteResponse.name,
                href: selectedSiteResponse.getLink('self'),
                object: selectedSiteResponse
            } : null
        };

        vm.regionSelect = {
            rootName: 'region',
            responseKeyPath: 'regions',
            openLink: rootResourceResponse.getLink('regions'),
            linkParameters: { active: true },
            loadOnOpen: true,
            selected: getFromLocalStorage('region'),
            onSelect: changeSelectedRegion,
            onRemove: removeSelectedRegion,
            disabled: false,
            populateDisabled: true
        };

        vm.userSelect = {
            rootName: 'userId',
            responseKeyPath: 'users',
            openLink: rootResourceResponse.getLink('users'),
            linkParameters: { status: 'active' },
            loadOnOpen: true,
            selected: getFromLocalStorage('userId'),
            onSelect: changeSelectedUser,
            onRemove: removeSelectedUser
        };

        vm.siteAssociateTypeSelect = {
            rootName: 'siteAssociateType',
            responseKeyPath: 'siteAssociateTypes',
            openLink: user.getLink('site-associate-types'),
            loadOnOpen: true,
            selected: getFromLocalStorage('siteAssociateType'),
            onSelect: changeSelectedAssociateType,
            onRemove: changeSelectedAssociateType
        };

        if (selectedSiteResponse !== null) {
            vm.regionSelect.disabled = true;
            vm.regionSelect.populateDisabled = true;
        }

        if (userCollectionResponse) {
            vm.userSelect.loadOnOpen = false;
            vm.userSelect.response = userCollectionResponse;

            if (vm.userSelect.selected) {
                vm.userSelect.selected = {
                    value: window.localStorage.getItem('userIdFilterName'),
                    object: {
                        id: window.localStorage.getItem('userIdFilter')
                    }
                };
            }
        }

        function reloadDashboard(object, overrides) {
            var siteHref = vm.siteSelect.selected ? vm.siteSelect.selected.object.getLink('self') : null;
            var params = {
                selectedSiteResource: siteHref ? siteHref.encode() : null,
                site: vm.siteSelect.selected ? vm.siteSelect.selected.object.id : null,
                siteIds: siteHref ? vm.siteSelect.selected.object.id : null,
                region: vm.regionSelect.selected ? vm.regionSelect.selected.object.id : null,
                userId: vm.userSelect.selected ? vm.userSelect.selected.object.id : null,
                siteAssociateType: vm.siteAssociateTypeSelect.selected ? vm.siteAssociateTypeSelect.selected.object.id : null,
                pointPage: vm.siteSelect.selected && $rootScope.previousStateParams.selectedSiteResource ? vm.state.params.pointPage : 1
            };

            if (!vm.siteSelect.selected) {
                params.patrolPointSiteId = null;
                params.pointPage = 1;
            }

            if (overrides) {
                params = angular.extend({}, params, overrides);
            }

            //clear value from local storage
            if (object.rootName) {
                var filter = object.rootName + 'Filter';
                clearCache(filter);

                //set new value in local storage
                if (object.selected) {
                    $window.localStorage.setItem(filter, object.selected.object.id);
                    $window.localStorage.setItem(filter + 'Name', object.selected.object.name);
                }
            }

            angular.forEach(Object.keys($rootScope.previousStateParams), function (stateParam) {
                if (stateParam && (stateParam.indexOf('page') > 0 || stateParam.indexOf('Page') > 0)) {
                    params[stateParam] = 1;
                }
            });

            $rootScope.skipDirtyCheck = true;

            $state.go('.', params, { reload: true }).then(function() {
                $rootScope.skipDirtyCheck = false;
            });
        }

        function getFromLocalStorage(key) {
           return JSON.parse(window.localStorage.getItem(key + 'Filter'));
        }

        function clearCache(key) {
            var toRemove = [];
            var i;

            for (i = 0; i < $window.localStorage.length; i++){
                //clearing multiselects
                if (key === 'multiselect_') {
                    if ($window.localStorage.key(i).substring(0, 6) === key) {
                        toRemove.push($window.localStorage.key(i));
                    }
                } else {
                    // Removing while iteration will change the length of storage
                    if ($window.localStorage.key(i) === key) {
                        toRemove.push($window.localStorage.key(i));
                    }
                }
            }

            for (i = 0; i < toRemove.length; i++){
                $window.localStorage.removeItem(toRemove[i]);
            }

            // Unset the array
            toRemove = [];
        }

        function changeSelectedSite() {
            var siteHref = vm.siteSelect.selected ? vm.siteSelect.selected.object.getLink('self') : null;
            var params = {
                selectedSiteResource: siteHref ? siteHref.encode() : null,
                siteIds : null
            };

            // Clear all multiselect_ cache
            clearCache('multiselect_');
            clearCache('siteFilter');

            if (! siteHref) {
                $rootScope.selectedSiteResource = null;
                selectedSiteResponse = null;
            }

            $window.localStorage.setItem('selectedSiteResource', siteHref ? siteHref.encode() : null);

            reloadDashboard(vm.siteSelect);
        }

        // if user is selected make a request to get memberships link
        function changeSelectedUser(object) {
            if (vm.userSelect.selected) {
                let params = {
                    clientAdminAccess: userManager.hasPermission('user_permission_client_admin_manage_users') &&
                                       !userManager.hasPermission('user_permission_manage_users')
                };

                apiClient.get(vm.userSelect.selected.object._links.self.href, params).then(function (response) {
                    let params = {
                        datagroup: 'search',
                        userId: vm.userSelect.selected.object.id
                    };

                    apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                        siteCollectionResponse = response;
                        vm.siteSelect = {
                            rootName: 'site',
                            responseKeyPath: 'sites',
                            response: response,
                            link: response.getLink('self'),
                            onSelect: changeSelectedSite,
                            onRemove: removeSelectedSite
                        };
                        $window.localStorage.setItem('userSelected', response.getLink('memberships'));
                        clearCache('siteFilter');
                        clearCache('selectedSiteResource');
                        reloadDashboard(object);
                    });
                });
            }
        }

        // if region is selected make a request to get list of sites
        function changeSelectedRegion(object) {
            if (vm.regionSelect.selected) {
                var params = {
                    datagroup: 'search',
                    regionId: vm.regionSelect.selected.object.id || null
                };
                if (vm.userSelect.selected) {
                    params.userId = vm.userSelect.selected.object.id;
                }

                apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                    siteCollectionResponse = response;
                    vm.siteSelect = {
                        rootName: 'site',
                        responseKeyPath: 'sites',
                        response: response,
                        link: response.getLink('self'),
                        onSelect: changeSelectedSite,
                        onRemove: removeSelectedSite
                    };
                    clearCache('siteFilter');
                    clearCache('selectedSiteResource');
                    reloadDashboard(object);
                });
            }
        }

        // if user is removed or changed then site filter is cleared and all possible sites are loaded
        function removeSelectedUser(object) {
            clearCache('siteFilter');
            clearCache('selectedSiteResource');
            reloadDashboard(object);
        }

        // if associate type is removed user field is cleared
        function changeSelectedAssociateType(object) {
            clearCache('userIdFilter');
            vm.userSelect.selected = null;
            reloadDashboard(object);
        }

        function removeSelectedSite() {
            clearCache('siteFilter');
            changeSelectedSite();
        }

        function removeSelectedRegion(object) {
            clearCache('regionFilter');
            //clears region filter
            reloadDashboard(object, {regionId: null});
        }

        function getSiteLinkParams() {
            var params = {};

            if (window.localStorage.getItem('regionFilter')) {
                params.regionId = window.localStorage.getItem('regionFilter');
            }

            // get sites specific user has access to, otherwise get all sites
            if (window.localStorage.getItem('userSelected') !== 'null'
                && window.localStorage.getItem('userSelected')
                && JSON.parse(window.localStorage.getItem('userIdFilter')) ) {
                params.region = window.localStorage.getItem('regionFilter') || null ;

                var membershipsLink = window.localStorage.getItem('userSelected');
                    return apiClient.get(membershipsLink, params).then(function (response) {
                        response.sites = response.memberships;
                        delete response.memberships;

                        for (var i = 0; i < response.sites.length; i++) {
                            response.sites[i].name = response.sites[i]._links.site.title;
                            response.sites[i].id = response.sites[i]._links.site.href.match(/\d+/)[0];
                            response.sites[i]._links.self.href = response.sites[i]._links.site.href;
                        }

                        return response;
                    });
            }

            return angular.extend({}, $stateParams, params);

        }

        function checkWidgets(value) {
            if (value.route === 'dashboard-statistics-open-flagged-priority-jobs') {
                return vm.prioritiesResponse.count > 0;
            }

            if (value.route === 'dashboard-statistics-open-integration-actions') {
                return vm.modulesService.checkIntegrationsEnable();
            }

            if (value.route.indexOf('dashboard-statistics-asset-score') === -1) {
                return true;
            }

            return false;
        }
    }
})();
