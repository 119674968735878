(function () {
    // 'use strict';

    /**
     * @desc ui.select expanded widget
     * @example <select-widget ng-model="vm.model"></select-widget>
     */
    angular
        .module('elogbooksDirectives')
        .directive('selectWidget', function (apiClient, lodash, messenger, $timeout) {
            return {
                restrict: 'A',
                templateUrl: '/modules/directives/form-elements/select-widget/select-widget.html',
                scope: {
                    model: '=ngModel',
                    highlighted: '@?'
                },
                require: ['ngModel'],
                link: function(scope, elem, attrs) {
                    if (attrs.id) {
                        scope.model.name = attrs.id;
                    }
                    if ('selectWidgetPlaceholder' in attrs) {
                        scope.placeholder = attrs.selectWidgetPlaceholder;
                    }

                    if (scope.model.loadmoreEnabled) {
                        scope.model.previousItems = [];
                        elem.bind('click', function () {
                            $timeout(function () {
                                bindScroll();
                            }, 100);
                        });
                    }

                    function bindScroll() {
                        var dropdown = elem.find('.ui-select-dropdown');

                        dropdown.unbind('scroll');
                        dropdown.bind('scroll', function (e) {
                            const scrollMargin = 100;

                            if (e.target.scrollHeight - e.target.clientHeight - scrollMargin <= e.target.scrollTop) {
                                angular.element(e.target).scope().model.loadMore(e);
                            }
                        });
                    }
                },
                controller: ['$scope', function ($scope) {
                    $scope.placeholder = 'START_TYPING_TO_SEARCH';
                    $scope.searched = false;
                    var defaultModel = {
                        response : null,
                        mapValue: null,
                        selected: null,
                        previouslySelected: null,
                        name : null,
                        link : null,
                        searchKey : $scope.searchKey ? $scope.searchKey : 'name',
                        linkParameters: {},
                        loading : false,
                        required : false,
                        disabled : false,
                        readonly: false,
                        populateDisabled: false,
                        allowAdd : false,
                        multiple: false,
                        removeSelected: false,
                        tagging: false,
                        items : [],
                        refreshOnLinkChange: true,
                        responseKeyPath: 'data',
                        itemHrefPath: '_links.self.href',
                        itemTitlePath: null,
                        itemValuePath: 'name',
                        criteriaValuePath: false,
                        isHierarchical: false,
                        index: null,
                        appendToBody: false,
                        itemDisabledKey: null,
                        itemDisabledKeyValue: true,
                        includeEmbeddedData: false,
                        itemDisabledHint: 'DISABLED',
                        loadOnOpen: false,
                        openLink: null,
                        formatValue: null,
                        map : function(keyPath, valuePath, titlePath) {
                            return function (item) {
                                if (typeof item === 'undefined'){
                                    return;
                                }

                                var highlighted = [];

                                if ($scope.highlighted) {
                                    highlighted = JSON.parse($scope.highlighted).filter(function(element) {
                                        return element.name === item.name;
                                    });
                                }
                                var object = {
                                    object: item,
                                    href :  lodash.get(item, keyPath),
                                    title : lodash.get(item, titlePath) ? lodash.get(item, titlePath) : lodash.get(item, valuePath),
                                    value : lodash.get(item, valuePath),
                                    suffix: item.status === 'INACTIVE' && $scope.model.highlightedSuffix ? '(Inactive)' : null,
                                    searchMatch: item.searchMatch,
                                    disabled: defaultModel.itemDisabledKey !== null && lodash.get(item, defaultModel.itemDisabledKey) === defaultModel.itemDisabledKeyValue,
                                    highlighted: highlighted.length > 0,
                                    highlightedSuffix: $scope.model.highlightedSuffix || false
                                };

                                if (typeof $scope.model.headers === "function") {
                                    $scope.headerChoices = true;
                                    object = $scope.model.headers(object);
                                }

                                return object;
                            };
                        },
                        loadMore: function ($event) {
                            if ($scope.model.loadmoreEnabled) {
                                if (this.loading) {
                                    return;
                                }

                                if ($scope.model.response.page === $scope.model.response.pages && !$scope.limitNotificationDisplayed) {
                                    $scope.limitNotificationDisplayed = true;
                                }

                                if ($scope.model.response.page < $scope.model.response.pages) {
                                    $event.stopPropagation();
                                    $event.preventDefault();

                                    this.loading = true;

                                    apiClient.get($scope.model.response.getLink('next'), this.linkParameters, 'short').then(function (response) {
                                        $scope.model.items = lodash.unionBy($scope.model.items, $scope.model.filterResponse(response), 'href').filter(function (item) {
                                            return !lodash.find($scope.model.selectedItems, {'href': item.href});
                                        });

                                        // We are safe to remove this condition and use js native concat to merge the arrays. 
                                        // just to keep it isolated and not breaking the select widget in other places adding this condition
                                        // Lodash merge modifying the original array in $scope.model.response
                                        if ($scope.model.removeSelected) {
                                            response[$scope.model.responseKeyPath] = $scope.model.response[$scope.model.responseKeyPath].concat(response[$scope.model.responseKeyPath]);
                                        } else {
                                            response[$scope.model.responseKeyPath] = lodash.merge($scope.model.response[$scope.model.responseKeyPath], response[$scope.model.responseKeyPath]);
                                        }

                                        $scope.model.response = response;
                                        $scope.model.loading = false;
                                    });
                                }
                            }
                        },
                        getResponseData: function (response) {
                            return lodash.get(response.getData(), this.responseKeyPath);
                        },
                        filterResponse : function (response) {
                            var responseData = this.getResponseData(response);

                            if (this.isHierarchical) {
                                var prefixed = responseData.map(function (item) {
                                    if (item['fullPathString']) {
                                        try {
                                            var fullPath = JSON.parse(item['fullPathString']);
                                            var fullTitle = fullPath.map(function (pathPart) {
                                                return pathPart['name'];
                                            }).join(' / ');
                                            var newItem = angular.extend({}, item);
                                            newItem[$scope.model.itemValuePath] = fullTitle;
                                            return newItem;
                                        } catch (e) {}
                                    }
                                    return item;
                                });

                                var result = prefixed.map(this.map(this.itemHrefPath, this.itemValuePath, this.itemTitlePath));
                                var currentKeyword = this.keyword;

                                if (this.keyword) {
                                    result = lodash.filter(result, function (item) {
                                        return item.title.toLowerCase().indexOf(currentKeyword.toLowerCase()) >= 0;
                                    });
                                }

                                return result;
                            }
                            if (responseData && $scope.model.removeSelected && $scope.model.selectedItems.length > 0) {
                                // remove items that are already selected
                                responseData = lodash.differenceBy(
                                    responseData,
                                    $scope.model.selectedItems,
                                    (item) =>
                                        lodash.get(item, this.itemHrefPath)
                                );
                            }
                            return responseData ? responseData.map(this.map(this.itemHrefPath, this.itemValuePath, this.itemTitlePath)) : [];
                        },
                        buildItems : function (response) {
                            this.items = this.filterResponse(response);
                        },
                        search : function(keyword) {
                            this.keyword = keyword;
                            this.loading = true;

                            if (!$scope.searched && keyword.length < 1) {
                                this.loading = false;
                                return;
                            }

                            if (keyword.length >= 1) {
                                $scope.searched = true;

                                if ($scope.model.loadmoreEnabled && $scope.model.previousItems.length === 0) {
                                    $scope.model.previousItems = $scope.model.items;
                                }
                            }

                            if (((this.oldSearchKeyword && keyword !== this.oldSearchKeyword && keyword.length === 0)
                                || keyword.length >= 1) && (this.link || this.openLink)) {
                                var searchParams = {};
                                searchParams[this.searchKey] = keyword;
                                // Async function needs to access object properties from global scope
                                var link = this.link ? this.link : this.openLink;
                                apiClient.get(link, angular.extend({}, this.linkParameters, searchParams), 'short').then(function (response) {
                                    $scope.model.loading = false;

                                    if ($scope.model.loadmoreEnabled) {
                                        $scope.model.response = response;

                                        if ($scope.model.previousItems.length > 0 && keyword.length === 0) {
                                            $scope.model.items = $scope.model.previousItems;
                                        } else {
                                            $scope.model.items = $scope.model.filterResponse(response);
                                        }
                                    } else {
                                        $scope.model.items = $scope.model.filterResponse(response);
                                    }

                                    // If title path is set the mapping breaks so we need to update it
                                    if ($scope.model.itemTitlePath) {
                                        for (var item in $scope.model.items) {
                                            if (!$scope.model.items.hasOwnProperty(item)) {
                                                continue;
                                            }
                                            $scope.model.items[item].value = $scope.model.items[item].title;
                                            $scope.model.items[item].title = undefined;
                                        }
                                    }

                                    addElement(keyword);
                                });
                            } else if (((this.oldSearchKeyword && keyword !== this.oldSearchKeyword && keyword.length === 0)
                                || keyword.length >= 1) && this.searchKey) {

                                    // filter response data locally based on seachKey and search term
                                    var responseData = this.getResponseData(this.response);
                                    var mappedData = responseData ? responseData.map(this.map(this.itemHrefPath, this.itemValuePath, this.itemTitlePath)) : [];
                                    var searchKey = this.searchKey;
                                    $scope.model.items = lodash.filter(mappedData, function(item) {
                                        if (!item.hasOwnProperty(searchKey)) {
                                            return true; // include if the item does not have the property speacified by seachKey
                                        }
                                        var result = typeof(item[searchKey]) === 'string' && item[searchKey].toLowerCase().includes(keyword.toLowerCase());
                                        return result;
                                    });

                                    addElement(keyword);
                                    this.loading = false;

                            } else {
                                addElement(keyword);
                                this.loading = false;
                            }

                            this.oldSearchKeyword = keyword;
                        },
                        loadData: function() {
                            this.loading = true;

                            if (this.loadOnOpen) {
                                if (this.openLink && $scope.model.items.length === 0) {
                                    apiClient.get(this.openLink, this.linkParameters, 'short').then(function (response) {
                                        $scope.model.items = $scope.model.filterResponse(response);

                                        if ($scope.model.selected) {
                                            var selected = lodash.find($scope.model.items, function(item) {
                                                return item.object.id === parseInt($scope.model.selected);
                                            });

                                            $scope.model.selected = selected.object ? {
                                                value: selected.object.name,
                                                object: selected.object
                                            } : null;
                                        }
                                    });
                                }
                            }

                            this.loading = false;
                        },
                        clear : function($event) {
                            if ($scope.model.disabled) {
                                return;
                            }

                            if (typeof $event !== 'undefined') {
                                $event.stopPropagation();
                            }

                            $scope.model.removedItem = $scope.model.selected;
                            this.selected = undefined;
                            $scope.model.selected = undefined;

                            if (
                                this.response &&
                                (!$scope.model.loadmoreEnabled || ($scope.model.loadmoreEnabled && $scope.model.items.length === 0))
                            ) {
                                this.buildItems(this.response);
                            }

                            if (typeof this.onRemove == 'function') {
                                this.onRemove($scope.model);
                            }
                        },
                        init: init
                    };

                    function init () {
                        // Merge with defaults
                        $scope.model = lodash.merge(defaultModel, $scope.model);

                        if (
                            $scope.model.response &&
                            (!$scope.model.loadmoreEnabled || ($scope.model.loadmoreEnabled && $scope.model.items.length === 0))
                        ) {
                            $scope.model.buildItems($scope.model.response);

                            if (!$scope.model.link && $scope.model.response.getLink('self')) {
                                $scope.model.link = $scope.model.response.getLink('self');
                            }
                        }

                        if ($scope.model.selected) {
                            if ($scope.model.selected && $scope.model.loadOnOpen) {
                                $scope.model.loadData();
                            }

                            if ($scope.model.selected && typeof $scope.model.selected.title !== 'undefined') {
                                $scope.model.selected.value = $scope.model.selected.title;
                            }
                        }

                        if ($scope.model.items.length == 0
                            && $scope.model.link
                            && !(lodash.has($scope, 'model.response') && $scope.model.response !== null)
                        ) {
                            apiClient.get($scope.model.link, $scope.model.linkParameters, 'short').then(function (itemsCollection) {
                                $scope.model.buildItems(itemsCollection);
                            });
                        }

                        if($scope.model.removeSelected && $scope.model.loadmoreEnabled) {
                            $scope.model.buildItems($scope.model.response);
                        }

                        $scope.model.requiredOriginal = $scope.model.required;
                    }

                    $scope.$watch('model.response', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            $scope.model.loading = false;
                            $scope.model.disabled = $scope.model.disabled ? $scope.model.disabled : false;
                            init();
                        }
                    });

                    $scope.$watch('model.selected', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            var s = $scope;
                            $scope.model.previouslySelected = oldValue;
                            init();
                        }
                    });

                    $scope.$watch('model.disabled', function(newValue) {
                        if (newValue && !$scope.model.populateDisabled) {
                            $scope.model.selected = undefined;
                        }

                        if ($scope.model.requiredOriginal) {
                            $scope.model.required = !newValue;
                        }
                    });

                    $scope.$watch('model.link', function(newValue, oldValue) {
                        if (newValue != oldValue && newValue && $scope.model.refreshOnLinkChange) {
                            $scope.model.loading = true;
                            return apiClient.get(newValue, $scope.model.linkParameters, 'short').then(function (itemsCollection) {
                                $scope.model.buildItems(itemsCollection);
                                $scope.model.loading = false;
                            });
                        }
                    });

                    init();

                    function checkIfExist(keyword) {
                        for (var item in $scope.model.items) {
                            if ($scope.model.items.hasOwnProperty(item)
                                && $scope.model.items[item].value.indexOf(keyword) > -1
                            ) {
                                return true;
                            }
                        }

                        return false;
                    }

                    function addElement(keyword) {
                        if (keyword.length >= 1
                            && $scope.model.allowAdd
                            && !checkIfExist(keyword)
                        ) {
                            $scope.model.items.unshift({value:keyword});
                        }
                    }
                }]
            };
        });
})();
