(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedQuoteFiltersModalController', advancedQuoteFiltersModalController);

    advancedQuoteFiltersModalController.$inject = [
        '$state', '$stateParams', 'config', 'serviceProviderResponse', 'regionCollectionResponse', 'operativeCollectionResponse',
        'approversCollectionResponse', 'creatorsCollectionResponse', 'priorityCollectionResponse', 'siteCollection',
        'siteGroupCollection', '$uibModalInstance', '$rootScope', '$scope', '$translate',
        'lodash', 'moment', 'paramConverterService', 'quoteRequestService', 'apiClient', 'rootResource',
        'coreClientsCollectionResponse', 'serviceProviderGroupsResponse', 'filterCriteriaService', 'elbSettings'
    ];

    function advancedQuoteFiltersModalController(
        $state, $stateParams, config, serviceProviderResponse, regionCollectionResponse, operativeCollectionResponse,
        approversCollectionResponse, creatorsCollectionResponse, priorityCollectionResponse, siteCollection,
        siteGroupCollection, $uibModalInstance, $rootScope, $scope, $translate, lodash, moment, paramConverterService,
        quoteRequestService, apiClient, rootResource, coreClientsCollectionResponse, serviceProviderGroupsResponse,
        filterCriteriaService, elbSettings
    ) {

        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.getApprovers = getApprovers;
        vm.getRegions = getRegions;
        vm.getServiceProviders = getServiceProviders;
        vm.getPriorities = getPriorities;
        vm.saveParams = saveParams;
        vm.clear = clear;
        vm.getPrioritiesBySite = getPrioritiesBySite;
        vm.budgetQuoteFilter = elbSettings.getSetting('budget_quotes_exist').value;
        var selectDisabled = false;

        if (siteCollection) {
            if (siteCollection.selected) {
                selectDisabled = true;
            }
        }

        vm.data = {};
        vm.advancedCriteria = {};

        var remedialOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        var overdueOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('OVERDUE_YES'),
                value: 'true'
            },
            {
                title: $translate.instant('OVERDUE_NO'),
                value: 'false'
            }
        ];

        function getApprovers() {
            return paramConverterService.formatResponse(approversCollectionResponse.users);
        }

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        function getServiceProviders() {
            return paramConverterService.formatResponse(serviceProviderResponse.serviceproviders);
        }

        function getPriorities() {
            return paramConverterService.formatResponse(priorityCollectionResponse.priorities);
        }

        vm.advancedCriteria = {
            quoteId: {
                type: 'number',
                title: 'ID',
                value: $stateParams.quoteId ? parseInt($stateParams.quoteId, 10) : null
            },
            overdue: { type: 'options', title: 'OVERDUE', value: $stateParams.overdue, options: overdueOptions},
            alarmedAtStart: {
                type: 'date',
                title: 'ALARMED_AT_START',
                value: (typeof $stateParams.alarmedAtStart !== 'undefined' && $stateParams.alarmedAtStart !== null) ? moment(new Date($stateParams.alarmedAtStart)) : null
            },
            alarmedAtEnd: {
                type: 'date',
                title: 'ALARMED_AT_END',
                end: !$stateParams.alarmedAtEnd,
                value: (typeof $stateParams.alarmedAtEnd !== 'undefined' && $stateParams.alarmedAtEnd !== null) ? moment(new Date($stateParams.alarmedAtEnd)) : null
            },
            createdAtStart: {
                type: 'date',
                title: 'CREATED_AT_START',
                value: (typeof $stateParams.createdAtStart !== 'undefined' && $stateParams.createdAtStart !== null) ? moment(new Date($stateParams.createdAtStart)) : null
            },
            createdAtEnd: {
                type: 'date',
                title: 'CREATED_AT_END',
                end: !$stateParams.createdAtEnd,
                value: (typeof $stateParams.createdAtEnd !== 'undefined' && $stateParams.createdAtEnd !== null) ? moment(new Date($stateParams.createdAtEnd)) : null
            },
            completionDueStart: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_START',
                value: (typeof $stateParams.completionDueStart !== 'undefined' && $stateParams.completionDueStart !== null) ? moment(new Date($stateParams.completionDueStart)) : null
            },
            completionDueEnd: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_END',
                end: !$stateParams.completionDueEnd,
                value: (typeof $stateParams.completionDueEnd !== 'undefined' && $stateParams.completionDueEnd !== null) ? moment(new Date($stateParams.completionDueEnd)) : null
            },
            lastSubmittedAtStart: {
                type: 'date',
                title: 'LAST_SUBMITTED_AT_START',
                value: (typeof $stateParams.lastSubmittedAtStart !== 'undefined' && $stateParams.lastSubmittedAtStart !== null) ? moment(new Date($stateParams.lastSubmittedAtStart)) : null
            },
            lastSubmittedAtEnd: {
                type: 'date',
                title: 'LAST_SUBMITTED_AT_END',
                end: !$stateParams.lastSubmittedAtEnd,
                value: (typeof $stateParams.lastSubmittedAtEnd !== 'undefined' && $stateParams.lastSubmittedAtEnd !== null) ? moment(new Date($stateParams.lastSubmittedAtEnd)) : null
            },
            reminderAtStart: {
                type: 'date',
                title: 'REMINDER_AT_START',
                value: (typeof $stateParams.reminderAtStart !== 'undefined' && $stateParams.reminderAtStart !== null) ? moment(new Date($stateParams.reminderAtStart)) : null
            },
            reminderAtEnd: {
                type: 'date',
                title: 'REMINDER_AT_END',
                end: !$stateParams.reminderAtEnd,
                value: (typeof $stateParams.reminderAtEnd !== 'undefined' && $stateParams.reminderAtEnd !== null) ? moment(new Date($stateParams.reminderAtEnd)) : null
            },
            quoteSummary: {type: 'text', class: 'full-width', title: 'SUMMARY', value: $stateParams.quoteSummary},
            quoteDescription: {type: 'text', class: 'full-width', title: 'DESCRIPTION', value: $stateParams.quoteDescription},
            quoteIncludeNeverSubmitted: { type: 'options', title: 'INCLUDE_NEVER_SUBMITTED', value: $stateParams.quoteIncludeNeverSubmitted, options: overdueOptions},
            quoteStatus: {type: 'options', title: 'STATUS', multiple: true, value: $stateParams.quoteStatus, options: quoteRequestService.getQuoteStatusesAsObject(vm.budgetQuoteFilter)}
        };

        if (operativeCollectionResponse && operativeCollectionResponse.count > 0) {
            var itemValuePath = '_links.user.id';
            var itemTitlePath = '_links.user.name';
            vm.advancedCriteria.operative = {
                type: 'data_select',
                title: 'OPERATIVE',
                options: {
                    itemHrefPath: '_links.user.href',
                    itemValuePath: itemValuePath,
                    itemTitlePath: itemTitlePath,
                    link: operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                    mapValue: 'operative',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: operativeCollectionResponse,
                    responseKeyPath: 'operatives',
                    selected: paramConverterService.getSelectedParam(
                        operativeCollectionResponse.operatives,
                        $stateParams.operative,
                        {
                            'itemValuePath': itemValuePath,
                            'itemTitlePath': itemTitlePath,
                            'searchObject': {
                                '_links': {
                                    'user': {
                                        'id': parseInt($stateParams.operative)
                                    }
                                }
                            }
                        })
                }
            };

        }

        if (approversCollectionResponse && approversCollectionResponse.users && approversCollectionResponse.users.length !== 0) {
            vm.advancedCriteria.approver = {
                type: 'data_select',
                title: 'APPROVER',
                options: {
                    mapValue: 'approver',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: approversCollectionResponse,
                    responseKeyPath: 'users',
                    selected : paramConverterService.getSelectedParam(approversCollectionResponse.users, $stateParams.approver)
                }
            };
        }

        if (serviceProviderResponse) {
            if (serviceProviderResponse.serviceproviders.length !== 0) {
                vm.advancedCriteria.serviceProviders = {
                    type: 'data_select',
                    title: 'SERVICE_PROVIDER',
                    options: {
                        mapValue: 'serviceProviders',
                        onRemove: vm.saveParams,
                        onSelect: vm.saveParams,
                        itemValuePath: 'id',
                        itemTitlePath: 'name',
                        response: serviceProviderResponse,
                        responseKeyPath: 'serviceproviders',
                        selected : paramConverterService.getSelectedParam(serviceProviderResponse.serviceproviders, $stateParams.serviceProviders)
                    }
                }
            }
        }

        if (regionCollectionResponse && regionCollectionResponse.regions.length !== 0) {
            vm.advancedCriteria.siteRegion = {
                type: 'data_select',
                title: 'REGION',
                isDisabled: selectDisabled,
                options: {
                    mapValue: 'siteRegion',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: regionCollectionResponse,
                    responseKeyPath: 'regions',
                    selected: paramConverterService.getSelectedParam(regionCollectionResponse.regions, !$stateParams.siteRegion ? $stateParams.region : $stateParams.siteRegion)
                }
            };
        }

        if (siteCollection && lodash.has(siteCollection, 'response.sites') && siteCollection.response.sites.length !== 0) {
            var commonSiteCriteria = {
                type: 'data_select',
                title: 'SITE',
                options: {
                    callback: getPrioritiesBySite,
                    disabled: siteCollection.selected !== null,
                    populateDisabled: true,
                    mapValue: 'sites',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: siteCollection.response,
                    responseKeyPath: 'sites',
                    selected: paramConverterService.getSelectedParam(siteCollection.response.sites, siteCollection.selected !== null ? siteCollection.selected.object.id : $stateParams.sites)
                }
            };

            if (siteCollection.selected) {
                commonSiteCriteria.value = paramConverterService.checkStateParams([siteCollection.selected.object.id]);
                vm.advancedCriteria.sites = commonSiteCriteria;
            } else {
                commonSiteCriteria.value = paramConverterService.checkStateParams($stateParams.sites);
                vm.advancedCriteria.sites = commonSiteCriteria;
            }
        }

        if (siteGroupCollection) {
            vm.advancedCriteria.siteGroups = {
                type: 'data_select',
                title: 'SITE_GROUP',
                isDisabled: selectDisabled,
                options: {
                    mapValue: 'siteGroups',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: siteGroupCollection,
                    responseKeyPath: 'sitegroups',
                    selected: paramConverterService.getSelectedParam(siteGroupCollection.sitegroups, $stateParams.siteGroups)
                }
            };
        }

        if (creatorsCollectionResponse && creatorsCollectionResponse.count > 0) {
            var creatorsOptions = {
                response: creatorsCollectionResponse,
                link: creatorsCollectionResponse ? creatorsCollectionResponse.getLink('self') : null,
                required: false,
                mapValue: 'createdBy',
                onRemove: vm.saveParams,
                selected : paramConverterService.getSelectedParam(creatorsCollectionResponse.users, $stateParams.createdBy),
                responseKeyPath: 'users',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'id',
                itemTitlePath: 'name',
                onSelect: vm.saveParams,
                populateDisabled: false
            };

            vm.advancedCriteria.createdBy = { type: 'data_select', title: 'CREATED_BY', options: creatorsOptions};
        }

        if (coreClientsCollectionResponse.coreclients) {
            vm.advancedCriteria.coreClient = {
                type: 'data_select',
                title: 'CORE_CLIENT',
                options: {
                    link: coreClientsCollectionResponse ? coreClientsCollectionResponse.getLink('self') : null,
                    mapValue: 'coreClient',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: coreClientsCollectionResponse,
                    responseKeyPath: 'coreclients',
                    selected: paramConverterService.getSelectedParam(coreClientsCollectionResponse.coreclients, $stateParams.coreClient)
                }
            };
        }

        if (serviceProviderGroupsResponse) {
            vm.advancedCriteria.serviceProviderGroups = {
                type: 'data_select',
                title: 'SERVICE_PROVIDER_GROUP',
                value: $stateParams.serviceProviderGroup,
                options: {
                    link: serviceProviderGroupsResponse ? serviceProviderGroupsResponse.getLink('self') : null,
                    mapValue: 'serviceProviderGroup',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: serviceProviderGroupsResponse,
                    responseKeyPath: 'groups',
                    selected: paramConverterService.getSelectedParam(serviceProviderGroupsResponse.groups, $stateParams.serviceProviderGroup)
                }
            };
        }

        vm.advancedCriteria.quoteRemedial = {
            type: 'options',
            title: 'REMEDIALS',
            value: $stateParams.quoteRemedial,
            options: remedialOptions
        }

        if (vm.budgetQuoteFilter) {
            vm.advancedCriteria.budgetQuote = {
                type: 'options',
                title: 'BUDGET_QUOTE',
                value: $stateParams.budgetQuote ? $stateParams.budgetQuote.toString() : null,
                options: [
                    {
                        title: $translate.instant('NONE_SELECTED'),
                        value: null
                    },
                    {
                        title: $translate.instant('YES'),
                        value: '1'
                    },
                    {
                        title: $translate.instant('NO'),
                        value: '0'
                    },
                    {
                        title: $translate.instant('Converted'),
                        value: '-1'
                    }
                ]
            };
        }

        var params = {};

        function getPrioritiesBySite(selectedSites) {
            params.sites = selectedSites;
            var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all, entity_type_quote', 'sites[]':selectedSites};

            apiClient.get(rootResource.getLink('priorities'), priorityLinkParameters).then(function(response) {
                vm.advancedCriteria.quotePriority = { type: 'options', title: 'PRIORITY', multiple: true, value: null, options: paramConverterService.formatResponse(response.priorities)};
            });
        }

        function saveParams (selectModel) {
            if (selectModel.callback) {
                selectModel.callback(selectModel.selected ? selectModel.selected.object.id : null);
            }

            // mapValue is for data select widget selections
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    if (selectModel.itemHrefPath === '_links.self.href') {
                        var itemIdPath = selectModel.itemIdPath ? selectModel.itemIdPath : 'id';
                        params[selectModel.mapValue] = selectModel.selected.object[itemIdPath];
                    } else {
                        params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemIdPath);
                    }
                }
            } else {
                params = selectModel;
            }

            if (typeof selectModel.response !== 'undefined' && selectModel.response.count == 1 && selectModel.response.selected == null) {
                var url = selectModel.response.getLink('self').replace(/&?id=[0-9]+/g, '');
                selectModel.link = url;

                apiClient.get(url).then(function(response) {
                    selectModel.response = response;
                });
            }
        }


        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function clear() {
            var clearValues = {};
            lodash.each($stateParams, function(value, key) {
                if (value) {

                    clearValues[key] = null;
                }
            });

            $state.go('.', angular.extend({}, clearValues), { reload: true });
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                $stateParams.quotesPage = '1';
            }

            $rootScope.skipDirtyCheck = true;
            // Clear save filter if there are changes
            if (!lodash.isEmpty(params) && filterCriteriaService.checkFilterChanges(params)) {
                filterCriteriaService.setSelectedFilter(null);
            }
            $state.go('.', angular.extend({}, params), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
