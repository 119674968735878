(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitePartnershipsController', UserSitePartnershipsController);

    UserSitePartnershipsController.$inject = ['rootResourceResponse', 'user', 'partnershipsResponse', '$state', '$stateParams', 'elbSettings', 'paramConverterService', 'serviceProviderService'];

    function UserSitePartnershipsController (rootResourceResponse, user, partnershipsResponse, $state, $stateParams, elbSettings, paramConverterService, serviceProviderService) {

        var vm = this;
        vm.partnerships = partnershipsResponse.getData().partnerships;
        vm.partnershipsResponse = partnershipsResponse;
        vm.originalPartnerships = angular.copy(vm.partnerships);
        vm.partnershipsChanged = false;
        vm.partnershipsResponse.canCreate = vm.partnershipsResponse.getLink('createSitePartnership') || vm.partnershipsResponse.getLink('createServiceProviderPartnership');
        vm.loadedCollections = [partnershipsResponse];

        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'user': user
        };

        vm.criteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name, clearValue: null},
            serviceProvidersTelephone: {type:'text', title: 'TELEPHONE', value: $stateParams.serviceProvidersTelephone, clearValue: null},
            serviceProvidersEmail: {type:'text', title: 'EMAIL', value: $stateParams.serviceProvidersEmail, clearValue: null},
            serviceProvidersAddress: {type:'text', title: 'ADDRESS', value: $stateParams.serviceProvidersAddress, clearValue: null},
        };

        if (vm.prosureEnabled) {
            vm.criteria.prosure360Status = {
                type: 'options',
                title: 'PROSURE360_STATUS',
                multiple: true,
                value:  paramConverterService.checkStateParams($stateParams.prosure360Status),
                options: serviceProviderService.prosure360StatusOptions
            };
        }

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function orderAction(key) {
            $stateParams.partnershipOrder = key;
            $state.go('.', $stateParams, {reload: $state.current});
        }
    }
})();
