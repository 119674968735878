(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedSiteFiltersModalController', advancedSiteFiltersModalController);

    advancedSiteFiltersModalController.$inject = ['$state', '$stateParams', 'config', 'siteGroupCollectionResponse', 'regionCollectionResponse', 'sectorCollectionResponse',
        'associateTypeCollectionResponse', 'siteAssociateCollectionResponse', '$uibModalInstance', '$rootScope', '$scope', '$translate', 'lodash', 'moment', 'paramConverterService',
        '$filter', 'apiClient', 'user', 'approversCollectionResponse'];

    function advancedSiteFiltersModalController($state, $stateParams, config, siteGroupCollectionResponse, regionCollectionResponse, sectorCollectionResponse,
             associateTypeCollectionResponse, siteAssociateCollectionResponse, $uibModalInstance, $rootScope, $scope, $translate, lodash, moment, paramConverterService,
             $filter, apiClient, user, approversCollectionResponse) {

        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.getSiteGroups = getSiteGroups;
        vm.getRegions = getRegions;
        vm.getAssociateTypes = getAssociateTypes;
        vm.getAssociates = getAssociates;
        vm.getSectors = getSectors;
        vm.saveParams = saveParams;
        vm.clear = clear;
        vm.getAssociatesByType = getAssociatesByType;
        var selectDisabled = false;

        vm.data = {};
        vm.advancedCriteria = {};

        var statusOptions = [
            {
                title: $filter('translate')('NONE_SELECTED'),
                value: null
            },
            {
                title: $filter('translate')('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $filter('translate')('STATUS_INACTIVE'),
                value: 'inactive'
            }
        ];

        function getAssociateTypes() {
            return paramConverterService.formatResponse(associateTypeCollectionResponse.siteAssociateTypes);
        }

        function getSiteGroups() {
            return paramConverterService.formatResponse(siteGroupCollectionResponse.sitegroups);
        }

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        function getAssociates() {
            return paramConverterService.formatResponse(siteAssociateCollectionResponse.users);
        }

        function getSectors() {
            return paramConverterService.formatResponse(sectorCollectionResponse.sectors);
        }

        vm.advancedCriteria = {
            id: { type: 'number', title: 'ID', value: $stateParams.id ? parseInt($stateParams.id, 10) : null },
            externalReference: { type: 'text', title: 'EXTERNAL_REFERENCE', value: $stateParams.externalReference },
            status: { type: 'options', title: 'STATUS', value: $stateParams.status, options: statusOptions },
            siteName: { type: 'text', title: 'NAME', value: $stateParams.siteName },
            reference: { type: 'text', title: 'REFERENCE', value: $stateParams.reference },
            address: { type: 'text', title: 'ADDRESS', value: $stateParams.address }
        };

        if (!!approversCollectionResponse?.users?.length) {
            vm.advancedCriteria.approver = {
                type: 'data_select',
                title: 'SITE_APPROVER',
                options: {
                    mapValue: 'approver',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: approversCollectionResponse,
                    responseKeyPath: 'users',
                    loadmoreEnabled: true,
                    selected: paramConverterService.getSelectedParam(approversCollectionResponse.users, $stateParams.approver)
                }
            };
        }

        if (sectorCollectionResponse) {
            vm.advancedCriteria.sectors = {
                type: 'data_select',
                title: 'SECTORS',
                options: {
                    mapValue: 'sectors',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: sectorCollectionResponse,
                    responseKeyPath: 'sectors',
                    selected: paramConverterService.getSelectedParam(sectorCollectionResponse.sectors, $stateParams.sectors)
                }
            };
        }

        if (siteGroupCollectionResponse) {
            var selected = $stateParams.siteGroup ? {
                title: JSON.parse($stateParams.siteGroup).n,
                value: JSON.parse($stateParams.siteGroup).v
            } : null;

            vm.advancedCriteria.siteGroup = {
                type: 'jsonselectwidget',
                title: 'SITE_GROUPS',
                value: $stateParams.siteGroup,
                options: {
                    mapValue: 'siteGroup',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: siteGroupCollectionResponse,
                    responseKeyPath: 'sitegroups',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selected
                }
            };
        }

        if (regionCollectionResponse) {
            vm.advancedCriteria.siteRegions = {
                type: 'data_select',
                title: 'REGION',
                options: {
                    mapValue: 'siteRegions',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: regionCollectionResponse,
                    responseKeyPath: 'regions',
                    selected: paramConverterService.getSelectedParam(regionCollectionResponse.regions, $stateParams.siteRegions)
                }
            };
        }

        if (associateTypeCollectionResponse) {
            vm.advancedCriteria.associateType = {
                type: 'data_select',
                title: 'SITE_ASSOCIATE_TYPES',
                options: {
                    callback: getAssociatesByType,
                    mapValue: 'associateType',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: associateTypeCollectionResponse,
                    responseKeyPath: 'siteAssociateTypes',
                    selected: paramConverterService.getSelectedParam(associateTypeCollectionResponse.siteAssociateTypes, $stateParams.associateType)
                }
            };
        }

        if (siteAssociateCollectionResponse) {
            vm.advancedCriteria.associate  = {
                type: 'data_select',
                title: 'SITE_ASSOCIATES',
                options: {
                    mapValue: 'associate',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: siteAssociateCollectionResponse,
                    responseKeyPath: 'users',
                    selected: paramConverterService.getSelectedParam(siteAssociateCollectionResponse.users, $stateParams.associate)
                }
            };
        }

        if ($state.current.name === 'dashboard.admin.sites.list') {
            vm.advancedCriteria.createdAtStart =  { type: 'date', value: $stateParams.createdAtStart, title: 'CREATED_AT_START' },
            vm.advancedCriteria.createdAtEnd =  { type: 'date', value: $stateParams.createdAtEnd, title: 'CREATED_AT_END' }
        }

        var params = {};

        function getAssociatesByType(selectedType) {
            if (vm.advancedCriteria.associate) {
                vm.advancedCriteria.associate.value = null;
                vm.advancedCriteria.associate.options = null;
            }
            params.associateType = selectedType;
            $stateParams.associate = null;
            if (selectedType !== null) {
                apiClient.get(user.getLink('users'), {associateType: selectedType}).then(function(response) {
                    vm.advancedCriteria.associate  = { type: 'options', title: 'SITE_ASSOCIATES', value: paramConverterService.checkNullAndConvert($stateParams.associate), options: paramConverterService.formatResponse(response.users)};
                });
            }
        }

        function saveParams(selectModel) {
            //mapValue is for data select widget selections
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    params[selectModel.mapValue] = selectModel.selected.object.id;
                }
            } else {
                params = selectModel;
            }

            if (typeof selectModel.response !== 'undefined'
                && selectModel.response.count == 1
                && selectModel.response.selected == null
            ) {
                var url = selectModel.response.getLink('self').replace(/&?id=[0-9]+/g, '');
                selectModel.link = url;

                apiClient.get(url).then(function(response) {
                    selectModel.response = response;
                });
            }
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        function clear() {
            var clearValues = {};
            lodash.each($stateParams, function(value, key) {
                if (value) {

                    clearValues[key] = null;
                }
            });

            $state.go('.', angular.extend({}, clearValues), { reload: true });
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                $stateParams.sitesPage = '1';
            }

            var override = {
                page: 1
            };

            $rootScope.skipDirtyCheck = true;
            $state.go('.', angular.extend({}, params, override), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
